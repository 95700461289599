import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Home";
import InvoiceDetail from "./InvoiceDetail";
import SuratJalan from "./SuratJalan";
import BeritaAcara from "./BeritaAcara";
import Kwitansi from "./Kwitansi";

function App() {
  return (
    <>
      <section className="top-bar">
        <span className="brand">BSM</span>

        <nav className="flex items-center ltr:ml-auto rtl:mr-auto">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=62811733466" className="btn btn_primary uppercase">
            <i className="la la-phone ltr:mr-2" /> Hubungi Kami
          </a>
        </nav>
      </section>

      <BrowserRouter>
        <Routes>
            <Route path="/:id/invoice" element={<InvoiceDetail />} />
            <Route path="/:id/surat-jalan" element={<SuratJalan />} />
            <Route path="/:id/berita-acara" element={<BeritaAcara />} />
            <Route path="/:id/kwitansi" element={<Kwitansi />} />
            <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
