import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {parseUrlQueryParams} from "./helper/formatter";
import axios from "axios";
import APP_CONFIG from "./config/app";
import moment from "moment";
import Utils from "./helper/utils";
import Report from "./Report";
import {ThreeDots} from "react-loader-spinner";
import SignModal from "./SignModal";
import {fabric} from 'fabric'
import $ from "jquery";
import { pdfjs } from "react-pdf"
import { jsPDF } from "jspdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
    pdfjs.version
}/pdf.worker.js`;

export default function SuratJalan() {
    const [loading, setLoading] = useState(true);
    const [invoiceCode, setInvoiceCode] = useState('');
    const [signCategoryId, setSignCategoryId] = useState(0);
    const [showSignModal, setShowSignModal] = useState(false);
    const [isSignDone, setIsSignDone] = useState(false);
    const [canvases, setCanvases] = useState([])
    const [editorMode, setEditorMode] = useState()
    const [hasSelectedObject, setHasSelectedObject] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [lastSaved, setLastSaved] = useState(false)

    const wrapperRef = useRef()

    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const {id} = params
        const query = parseUrlQueryParams(location.search);

        if (!query.token) {
            navigate('/')
        } else {
            axios.get(`${APP_CONFIG.API_URL}/public/transactions/${id}`, {
                headers: {
                    authentication: query.token
                }
            }).then(async response => {
                const {data, sign_as} = response.data;
                if (!sign_as) {
                    navigate('/')
                } else {
                    setSignCategoryId(sign_as)
                    let total = 0;
                    data.detail_transactions.forEach(dt => {
                        total += dt.price;
                    });
                    let start_date = moment(data.start_date, "Y-MM-DD");
                    let end_date = moment(data.end_date, "Y-MM-DD");
                    let diffInDay = end_date.diff(start_date, "days") + 1;
                    total = total * diffInDay;
                    data.diffInDay = diffInDay;

                    let products = [];
                    data.detail_transactions.forEach(detail_transaction => {
                        let exists = false;
                        products.forEach(product=>{
                            if(product.id === detail_transaction.product_detail.product_id){
                                exists = true;
                                product.serials.push(detail_transaction.product_detail.serial_number);
                            }
                        });
                        if(!exists){
                            let temp = detail_transaction.product_detail.product;
                            temp.serials = [detail_transaction.product_detail.serial_number];
                            temp.active_price = detail_transaction.price;
                            products.push(temp);
                        }
                    });
                    data.sisa = total;
                    data.detail_payments.forEach(pay => {
                        data.sisa -= pay.payment;
                    })
                    data.sisa -= data.discount;

                    if (data.with_tax) {
                        data.sisa += data.tax_amount
                    }

                    if (data.with_pph) {
                        data.sisa -= data.pph_amount
                    }

                    data.products = products;

                    setInvoiceCode(data.code)

                    let signExists = data.transaction_signs.findIndex(s => s.category === sign_as) !== -1;
                    await setIsSignDone(signExists)

                    if (!signExists) {
                        toggleSignModal();
                    }

                    const suratJalan = await Report.suratJalan(data)

                    suratJalan.getDataUrl(async (dataUrl) => {
                        pdfjs.getDocument(dataUrl).promise.then(async function(pdf) {
                            const pages = await Promise.all(Array(pdf.numPages).fill("").map((x, pageNumber) => {
                                return new Promise(resolve => {
                                    pdf.getPage(pageNumber + 1).then(async function(page) {
                                        const scale = 5;
                                        const viewport = page.getViewport({scale: scale});

                                        const canvas = document.createElement('canvas');
                                        $(wrapperRef.current).append(canvas)
                                        canvas.height = viewport.height;
                                        canvas.width = viewport.width;

                                        await page.render({
                                            canvasContext: canvas.getContext('2d'),
                                            viewport: viewport
                                        }).promise.then(async () => {
                                            const background = canvas.toDataURL("image/png");

                                            const fabricCanvas = new fabric.Canvas(canvas, {
                                                selection: false,
                                                objectCaching: false,
                                            });

                                            if (data.transaction_object) {
                                                setLastSaved(moment(data.transaction_object.updated_at))
                                                const surat_jalan_payloads = JSON.parse(data.transaction_object.surat_jalan_objects)
                                                if (surat_jalan_payloads[pageNumber]) {
                                                    fabricCanvas.loadFromJSON(surat_jalan_payloads[pageNumber])
                                                }
                                            }

                                            fabricCanvas.setBackgroundImage(background, fabricCanvas.renderAll.bind(fabricCanvas));

                                            fabricCanvas.on("selection:created", function(obj){
                                                setHasSelectedObject(true)
                                            });
                                            fabricCanvas.on("selection:cleared", function(obj){
                                                setHasSelectedObject(false)
                                            });

                                            resolve(fabricCanvas);
                                        })
                                    });
                                })
                            }))

                            await setCanvases(pages)
                            await setLoading(false)
                            setMode('move')
                        }, function (e) {
                            console.error(e)
                            navigate('/')
                        });
                    });
                }
            }).catch((e) => {
                console.error(e)
                navigate('/')
            })
        }
    }, [])

    const onResize = (e) => {
        canvases.forEach(c => {
            const outerCanvasContainer = $('.canvas-wrapper')[0];

            const ratio = c.getWidth() / c.getHeight();
            const containerWidth = outerCanvasContainer.clientWidth;

            const scale = containerWidth / c.getWidth();
            const zoom  = c.getZoom() * scale;
            c.setDimensions({width: containerWidth, height: containerWidth / ratio});
            c.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

            c.renderAll()
        })
    }

    const onDeletePress = (e) => {
        if (e.keyCode === 46) {
            canvases.forEach(c => {
                if (c.getActiveObject()) {
                    c.remove(c.getActiveObject()).renderAll();
                }
            })
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onDeletePress)
        window.addEventListener('resize', onResize)
        onResize()
        return () => {
            window.removeEventListener('keydown', onDeletePress)
            window.removeEventListener('resize', onResize)
        }
    })

    const toggleSignModal = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (isSignDone) {
            return
        }

        setShowSignModal(!showSignModal)
    }

    const submitSign = async (file) => {
        const {id} = params
        const query = parseUrlQueryParams(location.search);

        const payload = new FormData()
        payload.append('category', signCategoryId)
        payload.append('sign', file, 'sign.png')

        await axios.post(`${APP_CONFIG.API_URL}/public/transactions/${id}/sign`, payload, {
            headers: {
                authentication: query.token
            },
        });

        window.location.reload();
    }

    const setMode = (newMode) => {
        if (newMode === 'move') {
            setEditorMode(newMode)
            canvases.forEach(c => {
                c.isDrawingMode = false
                c.defaultCursor = 'default'
                c.mode = newMode

                c.off('mouse:move')
                .off('mouse:down')
                .on('mouse:move', function(opt) {
                    if (opt.target) {
                        if (opt.target.selectable) {
                            c.hoverCursor = 'move'
                        } else {
                            c.hoverCursor = 'pointer'
                        }
                    }
                });
            })
        } else if (newMode === 'text') {
            setEditorMode(newMode)
            canvases.forEach(c => {
                c.isDrawingMode = false
                c.discardActiveObject().renderAll()
                c.defaultCursor = 'text'

                c.off('mouse:move')
                .off('mouse:down')
                .on('mouse:down', function(e) {
                    let content = 'Tulis di sini'
                    const text = new fabric.IText(content,{
                        left: e.absolutePointer.x,
                        top: e.absolutePointer.y,
                        fill: 'black',
                        fontSize: 48
                    });
                    c.add(text).setActiveObject(text).renderAll();
                    text.enterEditing().setSelectionStart(content.length)
                    setMode('move')
                })
            })
        } else if (newMode === 'draw') {
            setEditorMode(newMode)
            canvases.forEach(c => {
                c.isDrawingMode = true
                c.freeDrawingBrush.width = 5;
                c.freeDrawingBrush.color = '#000';
                c.defaultCursor = 'crosshair'
                c.discardActiveObject().renderAll()

                c.off('mouse:move')
                .off('mouse:down')
                .on('mouse:move', function(opt) {
                    c.hoverCursor = 'crosshair'
                });
            })
        } else if (newMode === 'delete') {
            canvases.forEach(c => {
                c.remove(c.getActiveObject())
                c.renderAll()
                setHasSelectedObject(false)
            })
        }
    }

    const onDownload = () => {
        const doc = new jsPDF("p", "mm", "a4", true);

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        canvases.forEach((c, i) => {
            if (i !== 0) {
                doc.addPage()
            }
            doc.addImage(c.toDataURL({
                format: "image/jpeg",
                quality: 0.92,
                multiplier: 4
            }), 'JPEG', 0, 0, width, height, '', 'FAST');
        })

        doc.save(`Surat Jalan ${invoiceCode}.pdf`);
    }

    const onSave = async () => {
        const payloads = [];
        canvases.forEach(c => {
            const payload = c.toDatalessJSON();
            payload.backgroundImage = null
            payloads.push(payload)
        })
        const {id} = params
        const query = parseUrlQueryParams(location.search);

        const payload = new FormData()
        payload.append('surat_jalan_objects', JSON.stringify(payloads))

        setSaveLoading(true)

        try {
            await axios.post(`${APP_CONFIG.API_URL}/public/transactions/${id}/objects`, payload, {
                headers: {
                    authentication: query.token
                },
            });
            setLastSaved(moment())
        } catch (e) {
            console.error(e)
        }
        setSaveLoading(false)
    }

    console.log('isSignDone', isSignDone)

    return (
        <div className="container flex flex-col items-center justify-center mt-20 py-10 page-container">
            {showSignModal && <SignModal
                transactionId={params.id}
                categoryId={signCategoryId}
                onCancel={toggleSignModal}
                onFinish={submitSign}
            />}
            {
                loading ? (
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#0284c7"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                ) : (
                    <>
                        <h3 className="text-center mb-4">Surat Jalan {invoiceCode}</h3>
                        <div className="actions-container">
                            <div className="tools-container">
                                <button className={`btn ${editorMode === 'move' ? 'btn_primary' : 'btn_secondary'}`} onClick={() => setMode('move')}>
                                    <i className="fa-solid fa-arrows-up-down-left-right" />
                                </button>
                                <button className={`btn ${editorMode === 'text' ? 'btn_primary' : 'btn_secondary'}`} onClick={() => setMode('text')}>
                                    <i className="fa-solid fa-font" />
                                </button>
                                <button className={`btn ${editorMode === 'draw' ? 'btn_primary' : 'btn_secondary'}`} onClick={() => setMode('draw')}>
                                    <i className="fa-solid fa-pen" />
                                </button>
                                <button className={`btn btn_danger`} disabled={!hasSelectedObject} onClick={() => setMode('delete')}>
                                    <i className="fa-solid fa-trash" />
                                </button>
                            </div>
                            <div className="below-section">
                                {
                                    signCategoryId && (
                                        <button onClick={toggleSignModal} className="btn btn_primary" disabled={isSignDone}>
                                            <i className="fa-solid fa-signature" /> Tanda Tangan
                                        </button>
                                    )
                                }
                                <button className={`btn btn_primary`} onClick={onSave} disabled={saveLoading}>
                                    {
                                        saveLoading ? <i className="fa-solid fa-spin fa-spinner" /> : <i className="fa-solid fa-save" />
                                    }
                                    Simpan
                                </button>
                                <button className={`btn btn_primary`} onClick={onDownload}>
                                    <i className="fa-solid fa-download" />
                                    Download
                                </button>
                            </div>
                        </div>
                    </>
                )
            }

            {
                lastSaved && (
                    <div className="text-muted mb-4">Terakhir disimpan pada: {lastSaved.format('D MMM Y HH:mm:ss')}</div>
                )
            }
            <div className="canvas-wrapper" style={{ display: loading ? 'none' : '' }} ref={wrapperRef} />
        </div>
    )
}
