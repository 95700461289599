import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

