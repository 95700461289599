import React, {Component, Fragment} from "react";
import SignatureCanvas from "react-signature-canvas";
import Utils from "./helper/utils";

class SignModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.canvasRef = React.createRef();
    }

    onSave = async () => {
        const {onFinish} = this.props;

        if (this.canvasRef.isEmpty()) {
            alert('Tanda tangan masih kosong!')
            return
        }

        await this.setState({
            loading: true
        })
        try {
            const file = Utils.DataURIToBlob(this.canvasRef.toDataURL("image/png", 0.5))
            await onFinish(file);
        } catch (e) {}

        await this.setState({
            loading: false
        })
    }

    onClear = () => {
        this.canvasRef.clear();
    }

    render() {
        const {
            onCancel,
            categoryId
        } = this.props;
        const { loading } = this.state;

        let title = '';
        switch(categoryId) {
            case 1: title = 'Penyerahan - Admin'; break;
            case 2: title = 'Penyerahan - Gudang/Driver'; break;
            case 3: title = 'Penyerahan - Yang Menerima (Customer)'; break;
            case 4: title = 'Pengembalian - Yang Menerima'; break;
            case 5: title = 'Pengembalian - Gudang/Driver'; break;
            case 6: title = 'Pengembalian - Yang Menyerahkan (Customer)'; break;
        }

        return (
            <Fragment>
                <div className="overlay active" />
                <div className="modal fade in" id="updateModal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog_centered max-w-2xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="largeModalLabel">Tanda Tangan {title}</h4>
                            </div>
                            <div className="modal-body">
                                <SignatureCanvas
                                    ref={(r) => this.canvasRef = r}
                                    penColor='black'
                                    canvasProps={{width: 550, height: 200,}} />
                            </div>
                            <div className="modal-footer">
                                <button disabled={loading} onClick={this.onClear} type="button" className="btn btn_secondary waves-effect" style={{ marginRight: 'auto'}}>
                                    Clear
                                </button>
                                <button disabled={loading} onClick={() => onCancel()} type="button" className="btn btn_danger waves-effect ltr:mr-2">
                                    Batal
                                </button>
                                <button disabled={loading} onClick={this.onSave} type="button" className="btn btn_primary waves-effect">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade in" />
            </Fragment>
        )
    }
}

export default SignModal;
