import moment from 'moment'

class Utils{
    static downloadImage(data, filename = 'untitled.jpeg') {
        var a = document.createElement('a');
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    static DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    static getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    static rupiah(angka = 0, currency = 'Rp. '){
        let rupiah = '';
        if (!angka) angka = 0;
        let angkarev = angka.toString().split('').reverse().join('');
        for(let i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
        return currency+rupiah.split('',rupiah.length-1).reverse().join('');
    }
    static getRomawi(number){
        let romawi = "";
        if (typeof(number) === "string") number = parseInt(number, 10);
        switch(number){
            case 1 : romawi = "I"; break;
            case 2 : romawi = "II"; break;
            case 3 : romawi = "III"; break;
            case 4 : romawi = "IV"; break;
            case 5 : romawi = "V"; break;
            case 6 : romawi = "VI"; break;
            case 7 : romawi = "VII"; break;
            case 8 : romawi = "VIII"; break;
            case 9 : romawi = "IX"; break;
            case 10 : romawi = "X"; break;
            case 11 : romawi = "XI"; break;
            case 12 : romawi = "XII"; break;
            default : romawi = "";
        }
        return romawi;
    }

    static getTransactionStatusLabel = (status) => {
        switch(status){
            case "cancel" :
                return "Dibatalkan";
            case "on loan" :
                return "Sedang Dipinjam";
            case "finished" :
                return "Selesai";
            default :
                return "-";
        }
    }

    static doPaging(meta) {
        const paging = [];
        const range = meta.total_page > 5 ? 5 : meta.total_page;
        const start = 1;
        var i = Math.min(meta.total_page + start - range, Math.max(start, meta.current_page - (range / 2 | 0)));
        const end = i + range;
        while (i < end) { paging.push(i === meta.current_page ? {
            page: i++,
            active: true
        } : {
            page: i++,
            active: false
        }) }
        return paging;
    }
    static copyToClipboard(textToCopy) {
        const temp = document.createElement("input")
        temp.type = "text"
        temp.value = textToCopy

        document.body.appendChild(temp)
        temp.select()
        document.execCommand("Copy")
        document.body.removeChild(temp)
    }

    static terbilang(a){
        var bilangan = ['','Satu','Dua','Tiga','Empat','Lima','Enam','Tujuh','Delapan','Sembilan','Sepuluh','Sebelas'];

        // 1 - 11
        if(a < 12){
            var kalimat = bilangan[a];
        }
        // 12 - 19
        else if(a < 20){
            var kalimat = bilangan[a-10]+' Belas';
        }
        // 20 - 99
        else if(a < 100){
            var utama = a/10;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%10;
            var kalimat = bilangan[depan]+' Puluh '+bilangan[belakang];
        }
        // 100 - 199
        else if(a < 200){
            var kalimat = 'Seratus '+ this.terbilang(a - 100);
        }
        // 200 - 999
        else if(a < 1000){
            var utama = a/100;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%100;
            var kalimat = bilangan[depan] + ' Ratus '+ this.terbilang(belakang);
        }
        // 1,000 - 1,999
        else if(a < 2000){
            var kalimat = 'Seribu '+ this.terbilang(a - 1000);
        }
        // 2,000 - 9,999
        else if(a < 10000){
            var utama = a/1000;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%1000;
            var kalimat = bilangan[depan] + ' Ribu '+ this.terbilang(belakang);
        }
        // 10,000 - 99,999
        else if(a < 100000){
            var utama = a/100;
            var depan = parseInt(String(utama).substr(0,2));
            var belakang = a%1000;
            var kalimat = this.terbilang(depan) + ' Ribu '+ this.terbilang(belakang);
        }
        // 100,000 - 999,999
        else if(a < 1000000){
            var utama = a/1000;
            var depan = parseInt(String(utama).substr(0,3));
            var belakang = a%1000;
            var kalimat = this.terbilang(depan) + ' Ribu '+ this.terbilang(belakang);
        }
        // 1,000,000 - 	99,999,999
        else if(a < 100000000){
            var utama = a/1000000;
            var depan = parseInt(String(utama).substr(0,4));
            var belakang = a%1000000;
            var kalimat = this.terbilang(depan) + ' Juta '+ this.terbilang(belakang);
        }
        else if(a < 1000000000){
            var utama = a/1000000;
            var depan = parseInt(String(utama).substr(0,4));
            var belakang = a%1000000;
            var kalimat = this.terbilang(depan) + ' Juta '+ this.terbilang(belakang);
        }
        else if(a < 10000000000){
            var utama = a/1000000000;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%1000000000;
            var kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang);
        }
        else if(a < 100000000000){
            var utama = a/1000000000;
            var depan = parseInt(String(utama).substr(0,2));
            var belakang = a%1000000000;
            var kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang);
        }
        else if(a < 1000000000000){
            var utama = a/1000000000;
            var depan = parseInt(String(utama).substr(0,3));
            var belakang = a%1000000000;
            var kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang);
        }
        else if(a < 10000000000000){
            var utama = a/10000000000;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%10000000000;
            var kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang);
        }
        else if(a < 100000000000000){
            var utama = a/1000000000000;
            var depan = parseInt(String(utama).substr(0,2));
            var belakang = a%1000000000000;
            var kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang);
        }

        else if(a < 1000000000000000){
            var utama = a/1000000000000;
            var depan = parseInt(String(utama).substr(0,3));
            var belakang = a%1000000000000;
            var kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang);
        }

        else if(a < 10000000000000000){
            var utama = a/1000000000000000;
            var depan = parseInt(String(utama).substr(0,1));
            var belakang = a%1000000000000000;
            var kalimat = this.terbilang(depan) + ' Kuadriliun '+ this.terbilang(belakang);
        }

        var pisah = kalimat.split(' ');
        var full = [];
        for(var i=0;i<pisah.length;i++){
            if(pisah[i] != ""){full.push(pisah[i]);}
        }
        return full.join(' ');
    }
}

export default Utils;
