import {ThreeDots} from 'react-loader-spinner'
import {useEffect, useState, useRef} from "react";
import APP_CONFIG from "./config/app";
import axios from 'axios'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {parseUrlQueryParams} from "./helper/formatter";
import Report from "./Report";
import moment from 'moment'
import Utils from "./helper/utils";
import { pdfjs } from "react-pdf"
import $ from "jquery";
import { jsPDF } from "jspdf";
import {fabric} from "fabric";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
    pdfjs.version
}/pdf.worker.js`;

export default function InvoiceDetail() {
    const [loading, setLoading] = useState(true);
    const [invoiceCode, setInvoiceCode] = useState('');
    const [canvases, setCanvases] = useState([])
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const wrapperRef = useRef()

    useEffect(() => {
        const {id} = params
        const query = parseUrlQueryParams(location.search);

        if (!query.token) {
            navigate('/')
        } else {
            axios.get(`${APP_CONFIG.API_URL}/public/transactions/${id}`, {
                headers: {
                    authentication: query.token
                }
            }).then(async response => {
                const data = response.data.data;
                let total = 0;
                data.detail_transactions.forEach(dt => {
                    total += dt.price;
                });
                let start_date = moment(data.start_date, "Y-MM-DD");
                let end_date = moment(data.end_date, "Y-MM-DD");
                let diffInDay = end_date.diff(start_date, "days") + 1;
                total = total * diffInDay;
                data.diffInDay = diffInDay;

                let products = [];
                data.detail_transactions.forEach(detail_transaction => {
                    let exists = false;
                    products.forEach(product=>{
                        if(product.id === detail_transaction.product_detail.product_id){
                            exists = true;
                            product.serials.push(detail_transaction.product_detail.serial_number);
                        }
                    });
                    if(!exists){
                        let temp = detail_transaction.product_detail.product;
                        temp.serials = [detail_transaction.product_detail.serial_number];
                        temp.active_price = detail_transaction.price;
                        products.push(temp);
                    }
                });
                data.sisa = total;
                data.detail_payments.forEach(pay => {
                    data.sisa -= pay.payment;
                })
                data.sisa -= data.discount;

                if (data.with_tax) {
                    data.sisa += data.tax_amount
                }

                if (data.with_pph) {
                    data.sisa -= data.pph_amount
                }

                data.products = products;

                setInvoiceCode(data.code)

                Report.invoice(data).getDataUrl(async (dataUrl) => {
                    pdfjs.getDocument(dataUrl).promise.then(async function(pdf) {
                        const pages = await Promise.all(Array(pdf.numPages).fill("").map((x, pageNumber) => {
                            return new Promise(resolve => {
                                pdf.getPage(pageNumber + 1).then(async function(page) {
                                    const scale = 5;
                                    const viewport = page.getViewport({scale: scale});

                                    const canvas = document.createElement('canvas');
                                    $(wrapperRef.current).append(canvas)
                                    canvas.height = viewport.height;
                                    canvas.width = viewport.width;

                                    await page.render({
                                        canvasContext: canvas.getContext('2d'),
                                        viewport: viewport
                                    }).promise.then(async () => {
                                        const background = canvas.toDataURL("image/png");

                                        const fabricCanvas = new fabric.StaticCanvas(canvas, {
                                            selection: false,
                                            objectCaching: false,
                                        });

                                        fabricCanvas.setBackgroundImage(background, fabricCanvas.renderAll.bind(fabricCanvas));

                                        resolve(fabricCanvas);
                                    })
                                });
                            })
                        }))

                        await setCanvases(pages)
                        await setLoading(false)
                    }, function (e) {
                        console.error(e)
                        navigate('/')
                    });
                });
            }).catch((e) => {
                console.error(e)
                navigate('/')
            })
        }
    }, [])

    const onResize = (e) => {
        canvases.forEach(c => {
            const outerCanvasContainer = $('.canvas-wrapper')[0];

            const ratio = c.getWidth() / c.getHeight();
            const containerWidth = outerCanvasContainer.clientWidth;

            const scale = containerWidth / c.getWidth();
            const zoom  = c.getZoom() * scale;
            c.setDimensions({width: containerWidth, height: containerWidth / ratio});
            c.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

            c.renderAll()
        })
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        onResize()
        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    function downloadInvoice(e) {
        const doc = new jsPDF("p", "mm", "a4", true);

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        canvases.forEach((c, i) => {
            if (i !== 0) {
                doc.addPage()
            }
            doc.addImage(c.toDataURL({
                format: "image/jpeg",
                quality: 0.92,
                multiplier: 4
            }), 'JPEG', 0, 0, width, height, '', 'FAST');
        })

        doc.save(`Invoice ${invoiceCode}.pdf`);
    }

    return (
        <div className="container flex flex-col items-center justify-center mt-20 py-10 page-container">
            {
                loading ? (
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#0284c7"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                ) : (
                    <>
                        <h3 className="text-center mb-4">Invoice {invoiceCode}</h3>
                        <div className="actions-container">
                           <div className="below-section">
                               <a href="#" onClick={downloadInvoice} className="btn btn_primary">
                                   <i className="fa-solid fa-download" /> Download
                               </a>
                           </div>
                        </div>
                    </>
                )
            }

            <div className="canvas-wrapper" style={{ display: loading ? 'none' : '' }} ref={wrapperRef} />
        </div>
    )
}
